
function submitLogger(message, source, type = 'lead submitted') {

	var log_url = location.href.indexOf('dev.') !== -1 || location.href.indexOf('staging.') !== -1 || location.href.indexOf('.loc') !== -1 ? 'https://devlog.alternativemedia.com.au/log/lead' : 'https://log.alternativemedia.com.au/log/lead';

	$.ajax({
		url: log_url,
		dataType: 'json',
		type: 'POST',
		data: {
			message,
			type,
			source
		},
		success: function(data) {
			if(data.success)
				console.log(data);

			sessionStorage.clear();
		},
		error: function(e) {
			console.log(e);
			sessionStorage.clear();
		}
	});

}

// get the values on the checkbox
function getCheckedVal(name) {

	let result = '',
		selected = [];

	$(`input[name="${name}"]:checked`).each(function(){
		selected.push($(this).val());
	});

	result = selected.join(', ');

	return result;

}

function setSessionData(form) {

	let formData = {},
		current_session = sessionStorage.getItem('formData') !== null ? JSON.parse(sessionStorage.getItem('formData')) : {},
		stringData = '';

	if(typeof form !== 'undefined')
		formData = form.serializeObject();

	formData = {...formData, ...current_session};

	stringData = JSON.stringify(formData);
	
	sessionStorage.setItem('formData', stringData);

	return stringData;

}


// set session value
$(function(){

	// set session value on input
	$('input, select').each(function(){

		let el = $(this);

		el.on('change input blur', function(){

			let el_name = typeof el.attr('name') !== 'undefined' ? el.attr('name') : '',
				el_type = el.attr('type'),
				current_session = sessionStorage.getItem('formData') !== null ? JSON.parse(sessionStorage.getItem('formData')) : {};

			if(el_name !== '') {
				
				if(el_type == 'checkbox') {
					current_session[el_name] = getCheckedVal(el_name);

				} else {
					current_session[el_name] = el.val();
				}
				
				sessionStorage.setItem('formData', JSON.stringify(current_session));
			}
	
		});
	
	});


});
